export default [
  {
    title: 'Anasayfa',
    route: 'home',
    icon: 'HomeIcon',
    resource: 'DASHBOARD',
    action: 'read',
  },
  {
    title: 'Müşteri Kartları',
    route: 'Customers',
    icon: 'UsersIcon',
    children: [
      {
        title: 'Oluştur',
        route: 'CustomerAdd',
        resource: 'ADMIN_CUSTOMER_ADD',
        action: 'read',
      },
      {
        title: 'Listele',
        route: 'Customers',
        resource: 'ADMIN_CUSTOMER_LIST',
        action: 'read',
      },
    ],
  },
  {
    title: 'Tedarikçi Kartları',
    route: 'Suppliers',
    icon: 'UsersIcon',
    children: [
      {
        title: 'Oluştur',
        route: 'SupplierAdd',
        resource: 'ADMIN_SUPPLIER_ADD',
        action: 'read',
      },
      {
        title: 'Listele',
        route: 'Suppliers',
        resource: 'ADMIN_SUPPLIER_LIST',
        action: 'read',
      },
    ],
  },
  {
    title: 'Görüşmeler',
    route: 'Interviews',
    icon: 'MessageCircleIcon',
    children: [
      {
        title: 'Oluştur',
        route: 'InterviewCustomerSearch',
        resource: 'ADMIN_INTERVIEW_ADD',
        action: 'read',
      },
      {
        title: 'Görüşme Listesi',
        route: 'Interviews',
        resource: 'ADMIN_INTERVIEW_LIST',
        action: 'read',
      },
    ],
  },
  {
    title: 'Hatırlatmalar',
    route: 'Reminders',
    icon: 'BellIcon',
    children: [
      {
        title: 'Hatırlatmalarım',
        route: 'Reminders',
        resource: 'ADMIN_REMINDER_LIST',
        action: 'read',
      },
    ],
  },
  {
    title: 'Projeler',
    route: '#',
    icon: 'BoxIcon',
    children: [
      {
        title: 'Talepler',
        route: 'ProjectRequests',
        resource: 'ADMIN_PROJECT_LIST',
        action: 'read',
      },
      {
        title: 'Projeler',
        route: 'Projects',
        resource: 'ADMIN_PROJECT_LIST',
        action: 'read',
      },
    ],
  },
  {
    title: 'Teklifler',
    route: 'Offers',
    icon: 'FileTextIcon',
    children: [
      {
        title: 'Oluştur',
        route: 'OfferCustomerSearch',
        resource: 'ADMIN_OFFER_ADD',
        action: 'read',
      },
      {
        title: 'Listele',
        route: 'Offers',
        resource: 'ADMIN_OFFER_LIST',
        action: 'read',
      },
    ],
  },
  {
    title: 'Proformalar',
    route: '#',
    icon: 'BookIcon',
    children: [
      {
        title: 'Oluştur',
        route: 'ProformSearch',
        resource: 'ADMIN_PROFORMA_ADD',
        action: 'read',
      },
      {
        title: 'Proformalar',
        route: 'Proforms',
        resource: 'ADMIN_PROFORMA_LIST',
        action: 'read',
      },
    ],
  },

  {
    title: 'Sözleşmeler',
    route: '#',
    icon: 'FileTextIcon',
    children: [
      {
        title: 'Listele',
        route: 'Contracts',
        resource: 'ADMIN_CONTRACTS_LIST',
        action: 'read',
      },
      {
        title: 'Şablonlar',
        route: 'ContractTemplates',
        resource: 'ADMIN_DEFINES_ALL',
        action: 'read',
      },
    ],
  },
  {
    header: 'Yönetim',
    resource: 'ADMIN',
    action: 'read',
  },
  {
    title: 'Depo & Stok',
    route: 'Stocks',
    icon: 'InboxIcon',
    children: [
      {
        title: 'Toplu Stok Girişi',
        route: 'QuickStocksAdd',
        resource: 'ADMIN_STOCKS_ADD',
        action: 'read',
      },
      {
        title: 'Toplu Stok Çıkışı',
        route: 'QuickStocksOut',
        resource: 'ADMIN_STOCKS_ADD',
        action: 'read',
      },
      {
        title: 'Proje İmalat Stok Çıkışı',
        route: 'QuickStocksProject',
        resource: 'ADMIN_STOCKS_ADD',
        action: 'read',
      },
      {
        title: 'Stok Hareket Listesi',
        route: 'StockLists',
        resource: 'ADMIN_STOCKS_LIST',
        action: 'read',
      },
      {
        title: 'Stok Kartları',
        route: 'Stocks',
        resource: 'ADMIN_STOCKS_LIST',
        action: 'read',
      },
      {
        title: 'Depo Kartları',
        route: 'Warehouses',
        resource: 'ADMIN_WAREHOUSES_LIST',
        action: 'read',
      },
    ],
  },
  {
    title: 'Ürünler',
    route: 'Products',
    icon: 'CodesandboxIcon',
    children: [
      {
        title: 'Oluştur',
        route: 'ProductAdd',
        resource: 'ADMIN_PRODUCT_ADD',
        action: 'read',
      },
      {
        title: 'Ürün Kartları',
        route: 'Products',
        resource: 'ADMIN_PRODUCT_LIST',
        action: 'read',
      },
    ],
  },
  {
    title: 'Raporlar',
    icon: 'PieChartIcon',
    children: [
      {
        title: 'Görüşmeler',
        route: 'ReportInterviews',
        resource: 'ADMIN_REPORTS_INTERVIEW',
        action: 'read',
      },
      {
        title: 'Görüşme Dökümü',
        route: 'ReportInterviewExcel',
        resource: 'ADMIN_REPORTS_INTERVIEW_EXCEL',
        action: 'read',
      },
      {
        title: 'Teklifler',
        route: 'ReportOffers',
        resource: 'ADMIN_REPORTS_OFFER',
        action: 'read',
      },
      {
        title: 'Müşteri Karnesi',
        route: 'ReportCustomers',
        resource: 'ADMIN_REPORTS_CUSTOMERS',
        action: 'read',
      },
      {
        title: 'Müşteri Listesi',
        route: 'ReportCustomerExport',
        resource: 'ADMIN_REPORTS_CUSTOMER_LIST',
        action: 'read',
      },
      {
        title: 'Stok Durumları',
        route: 'StocksReport',
        resource: 'ADMIN_REPORTS_STOCKS',
        action: 'read',
      },
    ],
  },
  {
    title: 'Tanımlamalar',
    route: '#',
    icon: 'DatabaseIcon',
    children: [
      {
        title: 'Ülke',
        route: 'Countries',
        resource: 'ADMIN_DEFINES_ALL',
        action: 'read',
      },
      {
        title: 'Şehir',
        route: 'Cities',
        resource: 'ADMIN_DEFINES_ALL',
        action: 'read',
      },
      {
        title: 'Temas Tipi',
        route: 'Meets',
        resource: 'ADMIN_DEFINES_ALL',
        action: 'read',
      },
      {
        title: 'Sektör',
        route: 'Sectors',
        resource: 'ADMIN_DEFINES_ALL',
        action: 'read',
      },
      {
        title: 'Tedarikçi Sektörleri',
        route: 'SupplierSectors',
        resource: 'ADMIN_DEFINES_ALL',
        action: 'read',
      },
      {
        title: 'Faaliyet Alanları',
        route: 'Activities',
        resource: 'ADMIN_DEFINES_ALL',
        action: 'read',
      },
      {
        title: 'Tedarikçi Faaliyet Alanları',
        route: 'SupplierActivities',
        resource: 'ADMIN_DEFINES_ALL',
        action: 'read',
      },
      {
        title: 'İlgili Kişi Ünvanları',
        route: 'RelatedPersonTypes',
        resource: 'ADMIN_DEFINES_ALL',
        action: 'read',
      },
      {
        title: 'Para Birimleri',
        route: 'Currencies',
        resource: 'ADMIN_DEFINES_ALL',
        action: 'read',
      },
      {
        title: 'Birim Tipleri',
        route: 'Units',
        resource: 'ADMIN_DEFINES_ALL',
        action: 'read',
      },
      {
        title: 'Müşteri Tipleri',
        route: 'CustomerTypes',
        resource: 'ADMIN_DEFINES_ALL',
        action: 'read',
      },
      {
        title: 'Tedarikçi Tipleri',
        route: 'SupplierTypes',
        resource: 'ADMIN_DEFINES_ALL',
        action: 'read',
      },
      {
        title: 'Teklif Özel Şartları',
        route: 'OfferTerms',
        resource: 'ADMIN_DEFINES_ALL',
        action: 'read',
      },
      {
        title: 'Teklif Durumları',
        route: 'OfferStatuses',
        resource: 'ADMIN_DEFINES_ALL',
        action: 'read',
      },
      {
        title: 'Sözleşme Şablonları',
        route: 'ContractTemplates',
        resource: 'ADMIN_DEFINES_ALL',
        action: 'read',
      },
      {
        title: 'Görüşme Konuları',
        route: 'InterviewSubjects',
        resource: 'ADMIN_DEFINES_ALL',
        action: 'read',
      },
      {
        title: 'Görüşme Durumları',
        route: 'InterviewStatuses',
        resource: 'ADMIN_DEFINES_ALL',
        action: 'read',
      },
      {
        title: 'Proforma Özel Şartları',
        route: 'ProformaTerms',
        resource: 'ADMIN_DEFINES_ALL',
        action: 'read',
      },
      {
        title: 'Ürün Grupları',
        route: 'ProductGroups',
        resource: 'ADMIN_DEFINES_ALL',
        action: 'read',
      },
      {
        title: 'Stok Grupları',
        route: 'StockGroups',
        resource: 'ADMIN_DEFINES_ALL',
        action: 'read',
      },
      {
        title: 'Proje Durumları',
        route: 'ProjectStatuses',
        resource: 'ADMIN_DEFINES_ALL',
        action: 'read',
      },
      {
        title: 'Proje Doküman Tipleri',
        route: 'ProjectDocTypes',
        resource: 'ADMIN_DEFINES_ALL',
        action: 'read',
      },
      {
        title: 'Vergi Oranları',
        route: 'Taxes',
        resource: 'ADMIN_DEFINES_ALL',
        action: 'read',
      },
      {
        title: 'Bankalar',
        route: 'Banks',
        resource: 'ADMIN_DEFINES_ALL',
        action: 'read',
      },
      {
        title: 'Banka Hesapları',
        route: 'BankAccounts',
        resource: 'ADMIN_DEFINES_ALL',
        action: 'read',
      },
    ],
  },
  {
    title: 'Kullanıcılar',
    route: 'Users',
    icon: 'UsersIcon',
    children: [
      {
        title: 'Kullanıcılar',
        route: 'Users',
        resource: 'ADMIN_USERS',
        action: 'read',
      },
      {
        title: 'Kullanıcı Yetkileri',
        route: 'UserTypes',
        resource: 'ADMIN_USER_TYPES',
        action: 'read',
      },
    ],
  },
  {
    title: 'Ayarlar',
    route: '#',
    icon: 'SettingsIcon',
    children: [
      {
        title: 'SMTP',
        route: 'Smtp',
        resource: 'ADMIN_SMTP',
        action: 'read',
      },
      {
        title: 'Firma Listesi',
        route: 'ConfigCompany',
        resource: 'ADMIN_COMPANY_CONFIG',
        action: 'read',
      },
    ],
  },
]
